import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { get } from "lodash";
import { useLazyQuery } from "@apollo/react-hooks";
import { graphql } from "gatsby";
import { Button } from "antd";

import { ContentBlock, TextBlock } from "../../components/Blocks";
import Pages from "../../components/Pages";
import { ContentList } from "../../components/Lists";
import Breadcrumbs from "../../components/Layout/Breadcrumbs";
import seoData from "../../components/SEO/data";
import { formatURL, removeEmptyKeys, removePreloader } from "../../helpers";
import { Share } from "../../widgets";
import { MUSEUMS } from "../../queries/queries.graphql";
import { showMore } from "../../constants";
import { useThemeContext } from "../../components/Layout/ThemeContext";

export const query = graphql`
	query MuseumsPage($slug: String!, $pagePath: String!)  {
		hasura {
			...Museums
			...PageSettings
		}
	}
`;

export default function Museums({ data, pageContext }) {
	const { theme } = useThemeContext();
	const { slug, title_full } = pageContext;
	const limit = 4;

	function makeData(data = []) {
		return data.map(({ title_short, title_full, slug, main_image_preview, main_image }) => {
			const image = get(main_image_preview, "src", "") || get(main_image, "src", "");

			return {
				slug: formatURL(`${get(pageContext, "slug", "")}/${slug}`),
				image,
				title: title_short || title_full,
			};
		});
	}

	const [museums, setMuseums] = useState(makeData(get(data, "hasura.cities[0].museums", [])));
	const [offset, setOffset] = useState(limit);
	const url = `${get(pageContext, "slug", "")}/museums`;
	const seo = get(data, "hasura.page_settings[0]", {});

	const [loadMuseums, { called, loading, error, data: museumsData }] = useLazyQuery(
		MUSEUMS,
		{
			variables: {
				slug,
				limit,
			},
			partialRefetch: true,
		});

	useEffect(() => {
		if (called && !error && !loading) {
			setMuseums([...museums, ...makeData(get(museumsData, "cities[0].museums", []))]);
			setOffset(get(museumsData, "cities[0].museums", []).length < limit ? null : offset + limit);
		} else if (error) {
			setOffset(null);
		}

		removePreloader(!loading || error);
	}, [loading]);

	const breadcrumbsContext = {
		item: {
			slug,
			title_full,
		},
	};

	return (
		<Pages
			entity={seo || get(seoData, "museums_page", {})}
			url={url}
		>
			<ContentBlock>
				<div className="container">
					<Breadcrumbs currentLabel={"Музеи"} pageContext={breadcrumbsContext}/>
				</div>
			</ContentBlock>
			<ContentBlock>
				<div className={"container"}>
					<TextBlock title={"Музеи"}>
						<ContentList
							items={museums}
							className={"col-12 col-md-3"}
							style={{
								width: "100%",
								height: "400px",
								objectFit: "cover",
							}}/>
					</TextBlock>
					<div className="row justify-content-center mb-5">
						<Button
							type={"primary"}
							disabled={loading || !offset}
							loading={loading}
							onClick={() => loadMuseums({
								variables: {
									offset,
								},
							})}
							style={removeEmptyKeys({
								fontFamily: theme.fontFamily,
								fontSize: theme.fontSize.text,
								lineHeight: theme.lineHeight.text,
								color: theme.color.body,
								backgroundColor: theme.color.text,
							})}>
							{showMore}
						</Button>
					</div>
				</div>
			</ContentBlock>
			<div className={"container"}>
				<Share url={url} pageTitleShort={"Музеи"} />
			</div>
		</Pages>
	);
}

Museums.propTypes = {
	data: PropTypes.object,
	pageContext: PropTypes.object,
};

Museums.defaultProps = {
	data: {},
	pageContext: {
		slug: "",
		title_full: "",
	},
};
